import axios from 'axios';
import { isPlainObject } from 'lodash-es';

interface ApiValidationError {
  [key: string]: string[]
}

export interface StandardRequestError {
  error: {
    exception?: string,
    file?: string,
    line?: number,
    message?: string,
    trace?: unknown[]
  }
}

export interface ValidationError {
  error: {
    errors?: {
      [key: string]: string[]
    }
  }
}

export interface UnauthenticatedError {
  error: {
    message?: string
  }
}

export interface GeneralError {
  error: {
    exception?: string,
    file?: string,
    line?: number,
    message?: string,
    trace?: unknown[]
  }
}
// type checking for laravel response
// eslint-disable-next-line max-len
const typeChecking = <T extends { error?: unknown }>(errorObject: unknown): errorObject is T => (errorObject as T).error !== undefined && isPlainObject((errorObject as T).error);
// return true only if errorObject is object, has error params and error params is plain object

/**
 * For handling Typical Axios error returned from api based on laravel
 * @param error
 * @returns
 */
export const apiErrorHandling = (error: unknown): string => {
  if (error === undefined || error === null) {
    return '';
  }
  // if error is a string, just return it as is
  if (typeof error === 'string') {
    return error;
  }
  let errorMsg = '';
  const defaultErrorMsg = 'Something went wrong, please try again. If problem persists, please contact administrator.';

  // log the error to console if it's not a axios error
  // which usually means that the error is an internal error rather than a typical http error
  if (!axios.isAxiosError(error)) {
    console.error(error);
    return defaultErrorMsg;
  }
  let apiError: ApiValidationError | undefined;
  switch (error.response?.status) {
    case 400:
    case 429:
      // error 400-bad request,response format
      // error: { exception: "", file: "", line: XX, message: "", trace: [] }
      // error 429-too many request, response format
      // error: { exception: "", file: "", line: XX, message: "", trace: [] }

      // make sure error response data is match with specific laravel error format
      if (typeChecking<StandardRequestError>(error.response.data)) {
        errorMsg = error.response.data.error.message ?? '';
      }
      break;
    case 401:
      // error 401 - unauthenticated, response format - error: { message: "Unauthenticated." }
      if (typeChecking<UnauthenticatedError>(error.response.data)) {
        errorMsg = error.response.data.error.message ?? '';
      }
      // redirect user back to login page if API is 401
      window.location.href = '/login';
      break;
    case 422: {
      // error 422 - Unprocessable Entity/laravel validation
      // error (e.g. field missing or incorrect type etc.)
      // response format
      // error: { errors: { ad_accounts: [""], end_date: [""], pages: [""], start_date: [""] } }
      if (typeChecking<ValidationError>(error.response.data)) {
        apiError = error.response.data.error.errors ?? {};
        Object.values(apiError).forEach((value) => {
          errorMsg += value.reduce((lastErr, currentErr) => `${lastErr}${currentErr}\n`);
        });
      }
      break;
    }
    default:
      // other unknown error (e.g. undefined error status, 502 error etc)
      if (typeChecking<GeneralError>(error?.response?.data)) {
        errorMsg = error?.response?.data.error.message ?? '';
      }
      // also log the unknown error since it's possible that it's an unexpected bug on backend
      console.error(error);
      break;
  }

  // if errorMsg still empty after run through error handling, set default error msg
  if (errorMsg === '') {
    return defaultErrorMsg;
  }
  return errorMsg;
};
