import axios from 'axios';
import { useQuery, useMutation } from 'react-query';

interface Meta {
  current_page: number;
  last_page: number;
  path: string;
  per_page: number;
  total: number;
}

interface PaginatedApiResult<T> {
  data: T
  meta: Meta
}

export interface Dictionary {
  id: number;
  'keyword': string;
  'synonyms': string[];
  'weight': number;
}

export interface DictionaryGenerateRequest {
  id: number;
  status: 'STARTED' | 'COMPLETED' | 'ERROR' | 'REINDEXING'
  created_at: string
}

export const api = {
  dictionary: {
    list(page: number) {
      return axios.get<PaginatedApiResult<Dictionary[]>>('/api/dictionary', {
        params: {
          page,
        },
      });
    },
    get(id: string) {
      return axios.get<{ data: Dictionary }>(`/api/dictionary/${id}`);
    },
    create(data: Dictionary) {
      return axios.post('/api/dictionary', data);
    },
    edit(id: string, data:Dictionary) {
      return axios.put(`/api/dictionary/${id}`, data);
    },
    delete(id: string) {
      return axios.delete(`/api/dictionary/${id}`);
    },
    generate() {
      return axios.post('/api/dictionary/generate');
    },
    getLatestGenerateStatus() {
      return axios.get<{ data: DictionaryGenerateRequest | null }>('/api/dictionary/generate/status');
    },
  },
};

export const useGetDictionaryList = (page:number) => useQuery(`get-dictionary-list-${page}`, () => api.dictionary.list(page));

export const useGetDictionaryDetail = (id: string) => useQuery(`get-dictionary-detail-${id}`, () => api.dictionary.get(id));

export const useGetDictionaryGenStatus = () => useQuery('get-dictionyar-generate-status', () => api.dictionary.getLatestGenerateStatus());

export const useCreateDictionary = () => useMutation({
  mutationFn: (data: Dictionary) => api.dictionary.create(data),
});

export const useEditDictionary = () => useMutation({
  mutationFn: (data: { id: string, data: Dictionary }) => api.dictionary.edit(data.id, data.data),
});

export const useDeleteDictionary = () => useMutation({
  mutationFn: (data: { id: string }) => api.dictionary.delete(data.id),
});
