import * as React from 'react';
import { Layout, ConfigProvider } from 'antd';

// style and UI
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import Routes from '../routes';
import './main.css';
import '../../../../node_modules/antd/dist/antd.css';

interface Props {
  shadowRoot: HTMLElement
}

const queryClient = new QueryClient();
function Main(props: Props): JSX.Element {
  const { shadowRoot } = props;
  return (
    <ConfigProvider getPopupContainer={(triggerNode) => {
      if (triggerNode) {
        return triggerNode.parentNode as unknown as HTMLElement ?? shadowRoot;
      }
      return shadowRoot;
    }}
    >
      <QueryClientProvider client={queryClient}>
        <div className="w-full h-full flex justify-center">
          <Layout className="container w-full h-full px-10">
            <Routes />
          </Layout>
        </div>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default Main;
