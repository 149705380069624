import * as React from 'react';
import { Suspense } from 'react';
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom';
import { Layout, Spin } from 'antd';

// Pages
import DictionaryList from '../containers/dictionary/list';
import DictionaryCreate from '../containers/dictionary/create';
import DictionaryEdit from '../containers/dictionary/edit';

const { Content } = Layout;

interface RouteType {
  path: string;
  protected?: boolean;
  component: JSX.Element;
}

const routes: RouteType[] = [
  {
    path: '/dictionary',
    component: <DictionaryList />,
  },
  {
    path: '/dictionary/create',
    component: <DictionaryCreate />,
  },
  {
    path: '/dictionary/:id',
    component: <DictionaryEdit />,
  },
];
function LoadingScreen() {
  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center">
      <Spin size="large" />
      <div>Loading...</div>
    </div>
  );
}

function RouteContent(): JSX.Element {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Layout>
        <Content className="overflow-y-scroll">
          <Switch>
            {routes.map((route) => (
              <Route key={route.path} exact path={route.path}>
                {route.component}
              </Route>
            ))}
          </Switch>
        </Content>
      </Layout>
    </Suspense>
  );
}

function Routes(): JSX.Element {
  return (
    <BrowserRouter>
      <RouteContent />
    </BrowserRouter>
  );
}

export default Routes;
