import React from 'react';
import { notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import DictionaryForm from './form';
import { useCreateDictionary, Dictionary } from '../../api/index';
import { apiErrorHandling } from '../../api/errorHandler';

const backRoute = '/dictionary';

function DictionaryCreate() {
  const createMutation = useCreateDictionary();
  const [form] = useForm<Dictionary>();

  const onSubmitSuccess = (data: Dictionary) => {
    createMutation.mutate(data, {
      onSuccess: () => {
        window.location.href = backRoute;
      },
      onError: (error) => {
        notification.error({
          message: apiErrorHandling(error),
        });
      },
    });
  };

  return (
    <div>
      <DictionaryForm onSubmitSuccess={onSubmitSuccess} formInstance={form} />
    </div>
  );
}

export default DictionaryCreate;
