import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ConfigProvider, message, notification } from 'antd';
import Main from './containers/main';

/**
* Since old admin has its own set of css, which is from bootstrap, our new set of css doesn't work well with it, which basically removed all style from old admin.
* So a shadowroot is created to limit the scope of our new set of css.
*/
const root = document.getElementById('react-app');
if (!root) {
  throw new Error('element with react-app id not found');
}
const shadow = root.attachShadow?.({ mode: 'closed' }) || root;
const styleEl = document.createElement('link');
let appRoot = document.createElement('div');

appRoot = shadow.appendChild(appRoot);
styleEl.setAttribute('rel', 'stylesheet');
styleEl.setAttribute('href', `${process.env.ASSET_URL}/admin/admin.css`);
shadow.appendChild(styleEl);

// code to fix styling issue of antd component which bind to global root by default
message.config({
  getContainer: () => appRoot,
});

notification.config({
  getContainer: () => appRoot,
});

// eslint-disable-next-line import/prefer-default-export
export class App {
  constructor() {
    this.createShadowDOM();
    this.render();
  }

  private createShadowDOM(): void {

  }

  private render(): void {
    ReactDOM.render(React.createElement(Main, { shadowRoot: appRoot }), appRoot);
  }
}

// eslint-disable-next-line no-new
new App();
