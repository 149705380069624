import React, { useEffect } from 'react';
import { notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import DictionaryForm from './form';
import { Dictionary, useEditDictionary, useGetDictionaryDetail } from '../../api/index';
import { apiErrorHandling } from '../../api/errorHandler';
import useTypedParams from '../../routes/useTypedParams';

const backRoute = '/dictionary';

function DictionaryEdit() {
  const { id } = useTypedParams();
  if (!id) {
    throw new Error('id is not in url path.');
  }
  const detailQuery = useGetDictionaryDetail(id);
  const [form] = useForm<Dictionary>();
  useEffect(() => {
    if (detailQuery.isSuccess) {
      const fetchedData = detailQuery.data?.data.data;
      if (fetchedData) {
        form.setFieldsValue(fetchedData);
      }
    }
  }, [detailQuery.isSuccess, form, detailQuery.data?.data.data]);
  const editMutation = useEditDictionary();

  const onSubmitSuccess = (data: Dictionary) => {
    editMutation.mutate({ id, data }, {
      onSuccess: () => {
        window.location.href = backRoute;
      },
      onError: (error) => {
        notification.error({
          message: apiErrorHandling(error),
        });
      },
    });
  };

  return (
    <div>
      {!detailQuery.isLoading
          && <DictionaryForm onSubmitSuccess={onSubmitSuccess} formInstance={form} />}

    </div>
  );
}

export default DictionaryEdit;
