import * as React from 'react';
import {
  Table, Button, Popconfirm, notification,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMutation } from 'react-query';
import {
  Dictionary, useGetDictionaryList, useDeleteDictionary, useGetDictionaryGenStatus, api,
} from '../../api';
import { apiErrorHandling } from '../../api/errorHandler';

const createRoute = '/dictionary/create';

const confirmDeleteText = 'Are you sure you want to delete this record?';

function DictionaryList() {
  const query = useGetDictionaryList(1);
  const deleteMutation = useDeleteDictionary();
  const dictGenStatus = useGetDictionaryGenStatus();
  const genDictionaryMutation = useMutation({
    mutationFn: () => api.dictionary.generate(),
    onSuccess: () => {
      notification.success({
        message: 'generation started!',
      });
    },
    onError: (error) => notification.error({
      message: apiErrorHandling(error),
    }),
  });
  const columns: ColumnsType<Dictionary> = [
    {
      title: 'Keyword',
      dataIndex: 'keyword',
      key: 'keyword',
    },
    {
      title: 'Action',
      dataIndex: 'x',
      key: 'x',
      render: (_, data) => {
        const confirm = () => deleteMutation.mutate({ id: data.id.toString() }, {
          onSuccess: () => query.refetch(),
          onError: (error) => notification.error({
            message: apiErrorHandling(error),
          }),
        });
        return (
          <div>
            <Button onClick={() => {
              window.location.href = `/dictionary/${data.id}`;
            }}
            >
              修改
            </Button>
            <Popconfirm placement="rightTop" title={confirmDeleteText} onConfirm={confirm} okText="Yes" cancelText="No">
              <Button danger>刪除</Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  // allow dictionary generate only if dictionary generate status is not started
  const statusString = dictGenStatus.data?.data?.data?.status;
  const disableGenerateButton = statusString === 'STARTED' || statusString === 'REINDEXING';
  return (
    <div>
      <div className="flex justify-between">
        <div className="text-lg pl-2">字典</div>
        {dictGenStatus.data?.data?.data && (
        <div>
          <div>
            字典生成狀態:
            {` ${dictGenStatus.data.data.data.status}`}
          </div>
          <div>
            上一次字典生成開始時間:
            {` ${dictGenStatus.data.data.data.created_at}`}
          </div>
        </div>
        )}
        <div className="flex">
          <Button
            type="primary"
            className="mr-2"
            onClick={() => {
              document.location.href = createRoute;
            }}
          >
            新增
          </Button>
          <Button
            type="primary"
            onClick={() => {
              genDictionaryMutation.mutate();
            }}
            loading={genDictionaryMutation.isLoading}
            disabled={disableGenerateButton}
          >
            使字典變更生效
          </Button>
        </div>
      </div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={query.data?.data.data ?? []}
        loading={query.isLoading}
        pagination={{
          total: query.data?.data.meta.last_page ?? 1,
        }}
      />
    </div>
  );
}

export default DictionaryList;
