import {
  Button, Select, Form, Input,
} from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import React, { useRef, useState } from 'react';
import { Dictionary } from '../../api/index';

interface Props {
  onSubmitSuccess: (data: Dictionary) => void
  formInstance: FormInstance<Dictionary>
}

function DictionaryForm(props: Props) {
  const { onSubmitSuccess, formInstance } = props;
  const ref = useRef<HTMLElement>();
  const [submitting, setSubmitting] = useState(false);

  const onFinish = (values: Partial<Dictionary>) => {
    const data = {
      ...values,
    };
    if (!values.synonyms) {
      data.synonyms = [];
    }
    if (!values.keyword) {
      data.keyword = '';
    }
    if (!values.weight) {
      data.weight = 1;
    }
    setSubmitting(true);
    onSubmitSuccess(data as Dictionary);
    setSubmitting(false);
  };

  return (
    <Form<Dictionary>
      form={formInstance}
      name="DictionaryCreate"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="Keyword"
        name="keyword"
        rules={[{ required: true, message: 'Keyword is required!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="synonyms">
        <Select
            // getPopupContainer line is for fixing the syling issue of dropdown in shadowdom
          getPopupContainer={() => ref.current as HTMLElement}
          mode="tags"
          style={{ width: '100%' }}
          placeholder="同義詞"
          options={[]}
        />
      </Form.Item>
      <Form.Item name="weight" label="Weight">
        <Input type="number" min="0" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={submitting}>
          Submit
        </Button>
        <Button onClick={() => { window.location.href = '/dictionary'; }}>
          Back
        </Button>
      </Form.Item>
    </Form>
  );
}

export default DictionaryForm;
